const ID_TOKEN_KEY = "user_info" as string;

/**
 * @description get token form localStorage
 */
export const getUserInfo = (): any | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveUserInfo = (value: any): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(value));
};

/**
 * @description remove token form localStorage
 */
export const destroyUserInfo = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getUserInfo, saveUserInfo, destroyUserInfo };
