const APP_SETTINGS = "app_settings" as string;

/**
 * @description get token form localStorage
 */
export const getAppSettings = (): any | null => {
  return window.localStorage.getItem(APP_SETTINGS);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveAppSettings = (value: any): void => {
  window.localStorage.setItem(APP_SETTINGS, JSON.stringify(value));
};

/**
 * @description remove token form localStorage
 */
export const destroyAppSettings = (): void => {
  window.localStorage.removeItem(APP_SETTINGS);
};

export default { getAppSettings, saveAppSettings, destroyAppSettings };
