// const ID_TOKEN_KEY = "locale" as string;

/**
 * @description get token form localStorage
 */
export const getAllLocalStorage = (key:string): any | null => {
  return window.localStorage.getItem(key);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveAllLocalStorage = (key:string,value: any): void => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * @description remove token form localStorage
 */
export const destroyAllLocalStorage = (key:string): void => {
  window.localStorage.removeItem(key);
};

export default { getAllLocalStorage, saveAllLocalStorage, destroyAllLocalStorage };
