import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/wallets/summary",
    // redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/test",
        name: "test",
        component: () => import("@/views/test.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        redirect: "/wallets/summary",
        component: () => import("@/views/Dashboard.vue"),
        // component: () => import("@/views/wallets/Index.vue"),
      },
      //star 钱包相关
      {
        path: "/wallets",
        name: "wallets",
        redirect: "/wallets/summary",
        component: () => import("@/views/wallets/Index.vue"),
        children: [
          {
            path: "summary",
            name: "wallets_summary",
            component: () => import("@/views/wallets/summary/Index.vue"),
          },
          {
            path: "merchants",
            name: "wallets_merchants",
            redirect: "/wallets/merchants/list",
            component: () => import("@/views/wallets/merchants/Index.vue"),
            children:[
              {
                path: "list",
                name: "wallets_merchants_list",
                component: () => import("@/views/wallets/merchants/List.vue"),
                meta:{
                  keepAlive: true
                }
              },
              {
                path: "handle",
                name: "wallets_merchants_handle",
                component: () => import("@/views/wallets/merchants/Handle.vue"),
              },
            ]
          },
          // {
          //   path: "merchants/handle",
          //   name: "wallets_merchants_handle",
          //   component: () => import("@/views/wallets/merchants/Handle.vue"),
          // },
          {
            path: "orders",
            name: "wallets_orders",
            redirect: "/wallets/orders/list",
            component: () => import("@/views/wallets/orders/Index.vue"),
            children:[
              {
                path: "list",
                name: "wallets_orders_list",
                component: () => import("@/views/wallets/orders/List.vue"),
                meta:{
                  keepAlive: true
                }
              },
              {
                path: "info",
                name: "wallets_orders_info",
                component: () => import("@/views/wallets/orders/Info.vue"),
              },
            ]
          },
          {
            path: "account",
            name: "wallets_account",
            redirect: "/wallets/account/list",
            component: () => import("@/views/wallets/account/Index.vue"),
            children:[
              {
                path: "list",
                name: "wallets_account_list",
                component: () => import("@/views/wallets/account/List.vue"),
                meta:{
                  keepAlive: true
                }
              },
              {
                path: "list2",
                name: "wallets_account_list2",
                component: () => import("@/views/wallets/account/List2.vue"),
                meta:{
                  keepAlive: true
                }
              },
            ]
          },
          {
            path: "points",
            name: "wallets_points",
            component: () => import("@/views/wallets/points/Index.vue"),
          },
          {
            path: "settlement",
            name: "wallets_settlement",
            component: () => import("@/views/wallets/settlement/Index.vue"),
          },
          {
            path: "settlement-history",
            name: "wallets_settlement_history",
            component: () => import("@/views/wallets/settlement_history/Index.vue"),
          },
         
        ],
      },
      //end 钱包相关
      //start Business 相关
      {
        path: "/business",
        name: "business",
        redirect: "/business/user",
        component: () => import("@/views/business/Index.vue"),
        children: [
          {
            path: "user",
            name: "business_user",
            redirect: "/business/user/list",
            component: () => import("@/views/business/user/Index.vue"),
            children:[
              {
                path: "list",
                name: "business_user_list",
                component: () => import("@/views/business/user/List.vue"),
                meta:{
                  keepAlive: true
                }
              },
              {
                path: "info",
                name: "business_user_info",
                component: () => import("@/views/business/user/Info.vue"),
              },
            ]
          },
          {
            path: "tango",
            name: "business_tango",
            redirect: "/business/tango/list",
            component: () => import("@/views/business/tango/Index.vue"),
            children:[
              {
                path: "list",
                name: "business_tango_list",
                component: () => import("@/views/business/tango/List.vue"),
                meta:{
                  keepAlive: false
                }
              },
            ]
          },
         
        ],
      },
      //end Business 相关
      //start Operations
      {
        path: "/operations",
        name: "operations",
        redirect: "/operations/banner",
        component: () => import("@/views/operations/Index.vue"),
        children: [
          {
            path: "website",
            name: "operations_banner",
            redirect: "/operations/website/banner",
            component: () => import("@/views/wallets/merchants/Index.vue"),
            children:[
              {
                path: "banner",
                name: "operations_banner_list",
                component: () => import("@/views/operations/banners/List.vue"),
                meta:{
                  keepAlive: false
                }
              },
              {
                path: "used-special",
                name: "operations_used_special",
                component: () => import("@/views/operations/usedspecial/List.vue"),
                meta:{
                  keepAlive: false
                }
              },
              {
                path: "new-special",
                name: "operations_new_special",
                component: () => import("@/views/operations/newspecial/List.vue"),
                meta:{
                  keepAlive: false
                }
              },
              {
                path: "used-info",
                name: "operations_used_special_info",
                component: () => import("@/views/operations/usedspecial/Info.vue"),
              },
              {
                path: "new-info",
                name: "operations_new_special_info",
                component: () => import("@/views/operations/newspecial/Info.vue"),
              },
              {
                path: "banner-info",
                name: "operations_banner_info",
                component: () => import("@/views/operations/banners/Info.vue"),
              },

            ]
          },
        ],
      },
      //end Operations
      //start 文章相关
      {
        path: "/article",
        name: "article",
        redirect: "/article/articles",
        component: () => import("@/views/article/Index.vue"),
        children: [
          {
            path: "articles",
            name: "article_articles",
            redirect: "/article/articles/list",
            component: () => import("@/views/article/articles/Index.vue"),
            children:[
              {
                path: "list",
                name: "article_articles_list",
                component: () => import("@/views/article/articles/List.vue"),
                meta:{
                  keepAlive: true
                }
              },
            ]
          },
          
        ],
      },
      //end 文章相关
    
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-new",
        name: "password-new",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordNew.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  //订单详情页面
  {
    path: "/wallets/orders/details",
    name: "wallets_details",
    component: () => import("@/views/wallets/orders/Details.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // console.log('JwtService.getToken()',JwtService.getToken());
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
