import { createI18n } from "vue-i18n/index";

const messages = {
  zh:{
    "dashboard": "仪表盘",
    "leades":'线索',
    "sales":'销售',
    "ordersList":'订单列表',
    "status":'状态',
    "add source":'添加来源',
    "add status":'添加状态',
    "selected":'选择',
    "delete selected":'删除选中',
    "name":'名称',
    "actions":'操作',
    "name required":'名称为必填',
    "item required":'此项为必填',
    "add":'添加',
    "edit":'编辑',
    "discard":'重置',
    "submit":'提交',
    "please wait":'请稍后...',
    "sorry error":'很抱歉，似乎检测到一些错误，请重试。',
    "ok got it":'好的，继续',
    "form success":'表单已成功提交！',
    "source":'来源',
    "add leads":'添加线索',
    "please select":'请选择',
    "company":'公司',
    "individual":'个人',
    "address":'地址',
    "city":'城市',
    "state":'县/区',
    "zip":'邮编',
    "country":'国家',
    "phone":'手机号',
    "website":'网址',
    "currency symbol":'货币符号',
    "vat number":'增值税税号',
    "currency":'货币',
    "created at":'创建时间',
    "delete":'删除',
    "delete sure":'是否确认删除？',
    "sure":'确定',
    "cancel":'取消',
    "no data":'无数据',
    "setup":'设置',
    "settings":'设置',
    "projects":'项目',
    "search":'搜索',
    "filter":'筛选',
    "apply":'接受',
    "close":'关闭',
    "filter options":'筛选选项',
    "client id":'客户"id"',
    "title":'标题',
    "price":'价格',
    "start date":'开始日期',
    "end date":'结束日期',
    "labels":'标签',
    "description":'描述',
    "manage labels":'管理标签',
    "save":'保存',
    "success":'成功',
    "done":'完成',
    "client":'客户',
    "clients":'客户',
    "add client":'添加客户',
    "contacts":'联系人',
    "app settings":'应用设置',
    "localization":'本地化',
    "date format":'时间格式',
    "team":'团队',
    "team members":'团队成员',
    "first name":'名',
    "last name":'姓',
    "email":'邮箱',
    "job title":'职位',
    "sex":'性别',
    "password":'密码',
    "format error":'格式错误',
    "basic":'基本信息',
    "account settings":'账户设置',
    "password sure":'确认密码',
    "password match":'密码不匹配',
    "male":'男',
    "female":'女',
    "is active":'是否激活',
    "access permission":'访问权限',
    "role":'角色',
    "my profile":'个人资料',
    "type":'类型',
    "forgot password":"忘记密码",
    "enter your email to reset your password":"输入您的电子邮件以重置密码",
    "setup new password":"设置新密码",
    "already have reset your password":"已重置密码",
    "sign in here":"点击这里登录",
    "confirm password":"确认密码",
    "company name":"名称",
    "created time":"创建时间",
    "client group":"客户群",
    "owner":'所属人',
    "please enter":"请输入",
    "phone format error":"手机号格式错误",
    "projects count":"项目数量",
    "label":"标签",
    "milestone":"里程碑",
    "task":"任务",
    "priority":"优先级",
    "task priority":"任务优先级",
    "try again":'重试',
    "hide from kanban view":"是否从看板隐藏",
    "post comment":"发表评论",
    "write a comment":"写一个评论",
    "assigned to":"分配给",
    "task status":"任务状态",
    "kanban":"看板",
    "progress":"进度",
    "collaborator":"合作者",
    "member":"成员",
    "sign out":"退出",
    "file":"文件",
    "drop file here or click to upload":"将文件放到此处或单击以上传",
    "uploaded by":"上传者",
    "size":"尺寸",
    "category":"类别",
    "tax":"税收",
    "second tax":"第二税收",
    "max":"最大",
    "expense":"支出",
    "expense category":"支出类别",
    "must be greater than":"必须大于",
    "amount":"金额",
    "expense_date":"支出日期",
    "rate":"比例",
    "comment":"评论",
    "reply":"回复",
    "ticket":"工单",
    "ticket type":"工单类型"

  },
  en: {
    //begin::add
    dashboard: "Dashboard",
    leades:'Leades',
    sales:'Sales',
    ordersList:'Orders list',
    //begin::leads
    companyName:'Company name',
    primaryContact:'Primary contact',
    owner:'owner',
    createdDate:'Created date',
    //end::Orders leads
    //begin::Orders list
    orderId:'OrderId',
    client:'Client',
    orderDate:'Order date',
    amount:'Amount',
    status:'status',
    //end::Orders list
    add_source:'Add source',
    //end::add
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  },
  es: {
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
  },
  de: {
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    multiStepSignUp: "Multi-Steps-Anmeldung",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    multiStepSignUp: "マルチステップサインアップ",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendarApp: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    multiStepSignUp: "S'Inscrire Multi-Étapes",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
