import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import formCreate from '@form-create/element-ui'


import "@/core/plugins/prismjs";
import countTo from 'vue3-count-to';
const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(formCreate);
app.use(countTo);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
app.use(i18n);
app.mount("#app");
