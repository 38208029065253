const ID_TOKEN_KEY = "user_config" as string;

/**
 * @description get token form localStorage
 */
export const getConfig = (): any | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveConfig = (value: any): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(value));
};

/**
 * @description remove token form localStorage
 */
export const destroyConfig = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getConfig, saveConfig, destroyConfig };
