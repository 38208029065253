import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }
    /**
   * @description 设置请求的 baseURL
   * @param baseURL 请求的 baseURL
   */
    public static setBaseURL(): void {
      ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_FRONT_URL;
    }
  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.headers[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    // ApiService.vueInstance.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static setHeader2(): void {
    ApiService.vueInstance.axios.defaults.headers[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    // ApiService.vueInstance.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getToken()}`;
    // ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
    //   "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "multipart/form-data";
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource,  {params:params})
    .catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`)
    .catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params)
    .catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
      // throw new Error(ApiService);
    });
  }

  public static patch(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params)
    .catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
      // throw new Error(ApiService);
    });
  }

  public static upload(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params).catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params).catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
    });
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params).catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch(error => {
      const status=error.response.status;
      errorFn(status);
      throw error;
    });
  }
}
const errorFn=(status)=>{
  if(status==401){
    router.push({ name: "sign-in" });
  }
}
export default ApiService;
